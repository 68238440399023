<template>
  <v-container class="contenedor">
    <v-card class="px-5">
      <v-row>
        <v-col cols="9" class="ma-auto">
          <h1 class="blue">Configuración Inicial del Sistema</h1></v-col
        >
        <v-col cols="3" class="centradoImg">
          <v-img class="logo" src="/img/logo/logo.jfif" />
        </v-col>
        <!--  -->
        <v-col cols="12" class="blue">
          Bienvenido al asistente de configuración inicial de ACO, tu agente de
          carga online. Este proceso tomará alrededor de 10 minutos, pero es
          importante para que puedas utilizar todos los módulos del sistema.
        </v-col>
        <!--  -->
        <!-- <v-col cols="9" class="blue">
          Presiona <b>Omitir</b> para configurar el sistema en otro momento.
        </v-col>
        <v-col cols="3">
          <v-btn color="#A4A6B5" @click="Omitir()" block dark>
            Omitir en este momento
          </v-btn>
        </v-col> -->
        <!--  -->
        <v-col cols="9" class="blue">
          Presiona <b>Iniciar Configuración</b> para obtener todas las ventajas
          del sistema.
        </v-col>
        <v-col cols="3">
          <v-btn color="#2C3AB8" dark block @click="iniciarConfig()"
            >Iniciar Configuración</v-btn
          >
        </v-col>
        <v-col cols="3" offset="9" class="derecha">
          <BotonChateaConNostros />
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog1" persistent fullscreen scrollable>
      <v-card>
        <v-card-text class="pt-10 alturaModal">
          <v-row>
            <v-col cols="12" v-if="$store.state.enterprises.step == 1">
              <h1 class="blue">
                <v-avatar color="primary" dark size="40">
                  <span class="white--text">
                    {{ $store.state.enterprises.step }}
                  </span>
                </v-avatar>

                Datos de tu empresa y Administrador Principal del Sistema.
              </h1>
            </v-col>
            <v-col cols="12" v-if="$store.state.enterprises.step == 2">
              <h1 class="blue">
                <div class="titleCard">
                  <v-avatar color="primary" dark size="40">
                    <span class="white--text">
                      {{ $store.state.enterprises.step }}
                    </span>
                  </v-avatar>
                  Carga Masiva de Proveedores y Clientes
                </div>
                <v-subheader
                  class="my-10 mx-10 mt-10 subHeader"
                  v-if="
                    $store.state.enterprises.inicioFlag &&
                    $store.state.enterprises.step == 2
                  "
                >
                  Para facilitar la transición a nuestro sistema, puedes
                  importar proveedores y clientes todos de una vez utilizando
                  nuestra plantilla. 📄 <br />
                  👀 Encuentra los pasos a seguir en el tutorial adjunto.<br />
                  🕒 Si prefieres, puedes saltarte este paso y hacerlo más
                  tarde.
                </v-subheader>
              </h1>
            </v-col>

            <!--  -->
            <v-col cols="12" v-if="$store.state.enterprises.step == 3">
              <h1 class="blue">
                <v-avatar color="primary" dark size="40">
                  <span class="white--text">
                    {{ $store.state.enterprises.step }}
                  </span>
                </v-avatar>
                A continuación, vamos a crear la plantilla de proveedores y
                tarifas para cotizar.
              </h1>
            </v-col>
            <!--  -->
            <v-col
              cols="12"
              v-if="
                $store.state.enterprises.inicioFlag &&
                $store.state.enterprises.step == 1
              "
            >
              <v-btn
                color="#2C3AB8"
                class="ml-15 mx-5"
                dark
                @click="continuarLlenado()"
              >
                Iniciar
              </v-btn>
              <v-btn color="#A4A6B5" class="mx-5" @click="omitirStep()" dark
                >Omitir</v-btn
              >
            </v-col>

            <v-col cols="12" v-if="$store.state.enterprises.step == 4">
              <h1 class="blue">
                <v-avatar color="primary" dark size="40">
                  <span class="white--text">
                    {{ $store.state.enterprises.step }}
                  </span>
                </v-avatar>
                FIN DEL PROCESO
                <v-subheader class="my-10 mx-10 subHeader">
                  Gracias por proporcionar la información principal para la
                  configuración del sistema. Para comenzar a utilizar todas las
                  opciones disponibles, presiona el siguiente
                </v-subheader>
                <div class="centrado">
                  <v-btn dark color="#2C3AB8" @click="cerrarSesion()">
                    COMENZAR A USAR EL SISTEMA
                  </v-btn>
                </div>
                <div class="centradoImg">
                  <v-img class="finishcss" src="/img/jpg/finish.jpg" />
                </div>
              </h1>
            </v-col>
            <!-- --------------- PROVEEDOR - CLIENTE--------------- -->

            <v-col
              cols="12"
              v-if="
                $store.state.enterprises.inicioFlag &&
                $store.state.enterprises.step == 2
              "
            >
              <v-row class="px-10 mt-10">
                <v-col cols="8" class="subHeader">
                  Presiona Omitir para subir tus proveedores en otro momento.
                </v-col>
                <v-col cols="4">
                  <v-btn color="#A4A6B5" block dark @click="omitirStep()"
                    >Omitir en este momento</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="px-10">
                <v-col cols="8" class="subHeader">
                  Presiona Cargar Clientes y Proveedores para obtener todas las
                  ventajas del sistema.
                </v-col>
                <v-col cols="4">
                  <v-btn color="#2C3AB8" block dark @click="continuarLlenado()">
                    Cargar Clientes y Proveedores
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!--  -->
            <v-col
              cols="12"
              v-if="
                $store.state.enterprises.inicioFlag &&
                $store.state.enterprises.step == 3
              "
            >
              <v-btn
                color="#2C3AB8"
                class="ml-15 mx-5"
                dark
                @click="continuarLlenado()"
              >
                Iniciar
              </v-btn>
              <v-btn color="#A4A6B5" class="mx-5" dark @click="omitirStep()"
                >Omitir</v-btn
              >
            </v-col>

            <!--  -->
            <v-col
              cols="4"
              offset="4"
              v-if="$store.state.enterprises.datoEmpresaFlag"
            >
              <ConfiguraciónDatosEmpresa />
            </v-col>
            <!--  -->
            <v-col
              cols="4"
              offset="4"
              v-if="$store.state.enterprises.datoAdminFlag"
            >
              <ConfiguraciónDatosAdministrador />
            </v-col>
            <!--  -->
            <v-col
              cols="10"
              offset="1"
              v-if="$store.state.enterprises.datoProveedorFlag"
            >
              <ConfiguraciónCargaProveedor />
            </v-col>
            <!--  -->
            <v-col
              cols="10"
              offset="1"
              v-if="$store.state.enterprises.datoClienteFlag"
            >
              <ConfiguraciónCargaCliente />
            </v-col>
            <!--  -->
            <v-col
              cols="12"
              class="Costconfig"
              v-if="$store.state.enterprises.datoCostosFlag"
            >
              <ConfiguraciónCostos />
            </v-col>
          </v-row>
        </v-card-text>

        <v-row class="mt-info btn-actions">
          <v-col cols="1">
            <BotonChateaConNostros />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog1: false,
      mostrarFormularioEmpresa: false,
    };
  },
  components: {
    BotonChateaConNostros: () => import("../comun/botonChateaConNosotros.vue"),
    ConfiguraciónDatosEmpresa: () =>
      import("./ConfiguraciónDatosEmpresaComponent.vue"),
    ConfiguraciónDatosAdministrador: () =>
      import("./ConfiguraciónDatosAdministradorComponent.vue"),
    ConfiguraciónCargaCliente: () =>
      import("./ConfiguraciónCargaClienteComponent.vue"),
    ConfiguraciónCargaProveedor: () =>
      import("./ConfiguraciónCargaProveedorComponent.vue"),
    ConfiguraciónCostos: () => import("./ConfiguraciónCostosComponent.vue"),
  },
  async mounted() {
    this.$store.state.drawer = false;
    this.$store.state.spinner = true;
    this.$store.state.configEmpresa.datoEmpresa = JSON.parse(
      localStorage.getItem("dataBranch")
    )[0];
    this.$store.state.configEmpresa.dataUsuario = JSON.parse(
      localStorage.getItem("dataUser")
    )[0];
    await this.getModality();
    await this.getShipment();
    await this.getIncoterms();
    await this._getDocumentsPais();
    await this._getPais();
    await this._getEntities();
    this.$store.state.spinner = false;
  },
  methods: {
    ...mapActions([
      "getModality",
      "getShipment",
      "getIncoterms",
      "_getDocumentsPais",
      "_getPais",
      "_getEntities",
      "finalizarConfiguracion",
    ]),
    iniciarConfig() {
      this.$store.state.enterprises.step = 1;
      this.$store.state.enterprises.inicioFlag = true;
      this.$store.state.enterprises.datoEmpresaFlag = false;
      this.$store.state.enterprises.datoAdminFlag = false;
      this.$store.state.enterprises.datoProveedorFlag = false;
      this.$store.state.enterprises.datoClienteFlag = false;
      this.$store.state.enterprises.datoCostosFlag = false;
      this.dialog1 = true;
    },
    continuarLlenado() {
      if (this.$store.state.enterprises.step == 1) {
        this.$store.state.enterprises.inicioFlag = false;
        this.$store.state.enterprises.datoEmpresaFlag = true;
        return true;
      }
      if (this.$store.state.enterprises.step == 2) {
        this.$store.state.enterprises.datoProveedorFlag = true;
        this.$store.state.enterprises.inicioFlag = false;
        return true;
      }
      if (this.$store.state.enterprises.step == 3) {
        this.$store.state.enterprises.datoCostosFlag = true;
        this.$store.state.enterprises.inicioFlag = false;
        return true;
      }
    },
    async cerrarSesion() {
      await this.finalizarConfiguracion();
      localStorage.removeItem("auth-token");
      localStorage.removeItem("dataUser");
      localStorage.removeItem("dataBranch");
      this.$store.state.securitys.MostrarLogin = true;
      this.$store.state.securitys.MostrarSucursal = false;
      this.$router.push({ name: "Login" });
    },
    omitirStep() {
      let vm = this;
      vm.$store.state.enterprises.step++;
    },
    Omitir() {
      let dataBranch = JSON.parse(localStorage.getItem("dataBranch"));
      dataBranch[0].esnuevoflag = false;
      localStorage.setItem("dataBranch", JSON.stringify(dataBranch));
      this.$store.state.configEmpresa.config = false;
      this.$store.state.drawer = true;
      this.$router.push({
        name: "Main",
      });
      window.location.reload();
    },
    volver() {
      let vm = this;
      if (vm.$store.state.enterprises.step == 1) {
        if (vm.$store.state.enterprises.datoEmpresaFlag) {
          this.$store.state.enterprises.inicioFlag =
            !this.$store.state.enterprises.inicioFlag;
          vm.$store.state.enterprises.datoEmpresaFlag =
            !vm.$store.state.enterprises.datoEmpresaFlag;
          return false;
        }
        if (vm.$store.state.enterprises.datoAdminFlag) {
          vm.$store.state.enterprises.datoEmpresaFlag =
            !vm.$store.state.enterprises.datoEmpresaFlag;
          vm.$store.state.enterprises.datoAdminFlag =
            !vm.$store.state.enterprises.datoAdminFlag;
          return false;
        }

        if (vm.dialog1) {
          vm.dialog1 = false;
        }
      }
      if (this.$store.state.enterprises.step == 2) {
        this.$store.state.enterprises.step--;
      }
      if (this.$store.state.enterprises.step == 3) {
        this.$store.state.enterprises.datoCostosFlag = false;
        this.$store.state.enterprises.inicioFlag = true;
        this.$store.state.enterprises.step--;
      }
      if (this.$store.state.enterprises.step == 4) {
        this.$store.state.enterprises.datoCostosFlag = false;
        this.$store.state.enterprises.inicioFlag = true;
        this.$store.state.enterprises.step--;
      }
      // console.log(
      //   "datoEmpresaFlag",
      //   vm.$store.state.enterprises.datoEmpresaFlag
      // );
      // console.log("datoAdminFlag", vm.$store.state.enterprises.datoAdminFlag);
    },
  },
};
</script>

<style scoped>
* {
  color: #09305e;
}
.derecha {
  align-content: right !important;
  text-align: right !important;
}
.izquierda {
  align-content: left !important;
  text-align: left !important;
}
.centrado {
  align-content: center !important;
  text-align: center !important;
}
.logo {
  max-width: 25%;
  background: white;
  border-radius: 50%;
  border: 0px;
}
.centradoImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Esto asegurará que el contenedor tenga una altura definida */
}

.finishcss {
  margin: 20px 0% !important;
  max-width: 30%;
  background: white;
}

.blue {
  font-size: 1.4rem;
  background: none !important;
  color: #09305e !important;
}
.mt-info {
  /* padding: 0% 5%; */
  /* margin: 5% 0 0 0; */
}
.subHeader {
  font-size: x-large !important;
  line-height: 1.2 !important;
  text-align: justify;
  color: #09305e !important;
}
.Costconfig {
  min-height: 120vh !important;
}
.btn-actions {
  /* background: red; */
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 999;
}
.contenedor {
  max-width: 90% !important;
}
.alturaModal {
  height: 120vh !important;
}
.titleCard {
  min-height: 80px !important;
}
</style>
